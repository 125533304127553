<template>
  <div class="container">
    <v-card>
      <v-card-title>{{ $vuetify.lang.t("$vuetify.inventory.inventorySearch") }}</v-card-title>
      <v-card-text>
        <div class="d-flex justify-start justify-sm-center">
          <v-radio-group :row="isMobile ? false : true" v-model="searchMethod">
            <v-radio value="id" :label="$vuetify.lang.t('$vuetify.label.id')" />
            <v-radio value="documentId" :label="$vuetify.lang.t('$vuetify.base.documentNumber')" />
            <v-radio value="location" :label="$vuetify.lang.t('$vuetify.worksheet.location')" />
          </v-radio-group>
        </div>
        <div class="d-flex flex-column flex-md-row justify-center">
          <v-select
            :label="$vuetify.lang.t('$vuetify.worksheet.chooseLocation')"
            placeholder="Choose a location to start search"
            v-if="searchMethod == 'location'"
            :items="locations"
            item-text="name"
            item-value="id"
            clearable
            v-model="locationId"
            @click:clear="clearSearch"
          />
          <v-text-field
            v-else
            :label="searchMethod == 'id' ? $vuetify.lang.t('$vuetify.label.id') : $vuetify.lang.t('$vuetify.base.documentNumber')"
            :placeholder="
              searchMethod == 'id'
                ? 'Separate IDs with comma (ex: 123, 321, 344)'
                : ''
            "
            clearable
            v-model="searchTerm"
            @keypress.enter="search"
            @click:clear="clearSearch"
          ></v-text-field>
          <v-btn class="ml-0 ml-md-5 mt-3" @click="search">
            {{ $vuetify.lang.t("$vuetify.base.search") }}
          </v-btn>
        </div>
        <div class="text-caption text-center ma-6">
          {{ $vuetify.lang.t("$vuetify.label.foundX", totalSizes ? totalSizes : 0 ) }}
        </div>
        <div
          class="my-5"
          v-if="
            searchMethod == 'location' &&
            locationSummary &&
            locationSummary.length > 0
          "
        >
          <div class="text-caption mb-2">Location Summary:</div>
          <v-row
            no-gutters
            class="d-flex justify-start"
            v-for="product in locationSummary"
            :key="product.productId"
          >
            <v-col>{{ product.productName }} </v-col>
            <v-col> {{ product.unitAvailable }} {{ product.productInfo ? product.productInfo.measureUnit : "" }} </v-col>
            <v-col class="flex-grow-1">
              {{ product.quantityAvailable | formatNumber }}
              {{ product.productInfo ? product.productInfo.measureWeight : "" }}
            </v-col>
          </v-row>
        </div>
        <infinite-scroll
          :key="infiniteScrollKey"
          @fetchData="fetchData"
          :totalPages.sync="totalPages"
        >
          <v-sheet
            v-ripple
            :elevation="2"
            class="my-2 pa-2"
            v-for="row in results"
            :key="row.id"
            @click="viewInventoryDetails(row)"
          >
            <div class="d-flex justify-space-between text-caption mb-4">
              <span> {{$vuetify.lang.t('$vuetify.label.id')}}: {{ row.id }}</span>
              <span> {{$vuetify.lang.t('$vuetify.inventory.documentNo')}}: {{ row.documentNumber ? row.documentNumber:'n/a' }}</span>
            </div>
            <div class="flex-grow-1 text-center">
              <div class="font-weight-light">{{ translateStatus(row.status) }}</div>
              <div class="font-weight-bold ma-2">{{ row.productInfo ? row.productInfo.name:'' }}</div>
              <div>
                {{
                  ((row.quantityAvailable
                    ? row.quantityAvailable
                    : row.quantity) -
                    row.reservedQuantity)
                    | formatNumber
                }}
                {{ row.productInfo ? row.productInfo.measureWeight : "" }}
              </div>
              <div class="text-caption" v-if="row.reservedQuantity && row.reservedQuantity > 0">
                  {{$vuetify.lang.t('$vuetify.inventory.reserved')}}: {{ row.reservedQuantity | formatNumber}} {{ row.productInfo ? row.productInfo.measureWeight : "" }}
              </div>
            </div>
            <div class="d-flex justify-space-between mt-4 text-caption">
              <span>{{$vuetify.lang.t('$vuetify.worksheet.location')}}: {{ locationName(row.locationId) }}</span>
              <div>{{ row.stockTime | formatDateYear }}</div>
            </div>
          </v-sheet>
        </infinite-scroll>
      </v-card-text>
    </v-card>

    <div class="d-flex justify-space-between my-5">
      <v-btn @click="goBack"> {{$vuetify.lang.t('$vuetify.base.back')}} </v-btn>
    </div>

    <v-bottom-sheet scrollable v-model="showInventoryDetails">
      <v-card>
        <v-card-title class="justify-center">
          <v-btn class="mt-6" text @click="showInventoryDetails = false">
            {{$vuetify.lang.t('$vuetify.close')}}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <inventory-details
            :key="selectedInventoryId"
            v-model="selectedInventoryId"
            @updatedLocation="updateLocation"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const InventoryDetails = () =>
  import("@/components/inventory/InventoryDetails.vue");
import { tagReservedInventories } from "@/services/inventoryReservation.js";

export default {
  components: { InventoryDetails },
  data: () => {
    return {
      searchMethod: "documentId",
      infiniteScrollKey: Date.now(),
      totalPages: 0,
      totalSizes: null,
      searchTerm: null,
      results: [],
      showInventoryDetails: false,
      selectedInventoryId: null,
      locationId: null,
      locationSummary: null,
    };
  },
  computed: {
    ...mapGetters("locations", ["locations"]),
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  mounted() {
    const initMethod = this.$route.query.method;
    const initSearch = this.$route.query.search;
    if (initMethod && ["id", "documentId"].includes(initMethod)) {
      this.searchMethod = initMethod;
    }
    if (initSearch) {
      this.searchTerm = initSearch;
      this.search();
    }
    this.loadLocations();
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("categoryStore", ["fetchCategory"]),
    ...mapActions("locations", ["loadLocations"]),

    goBack() {
      this.$router.go(-1);
    },
    fetchData({ page, done, empty }) {
      if (this.searchMethod == "location") {
        this.fetchUsingLocation({ page, done, empty });
      } else if (this.searchTerm) {
        if (this.searchMethod == "id") {
          this.fetchUsingIds({ done });
        } else {
          this.fetchUsingDocumentNumber({ page, done, empty });
        }
      } else {
        empty();
      }
    },
    fetchUsingDocumentNumber({ page, done, empty }) {
      const params = {
        page,
        size: 5,
        sort: "id,desc",
        documentId: this.searchTerm,
      };
      this.$axios
        .get("/inventory/search/document/" + this.searchTerm, { params })
        .then(async (resp) => {
          const newPage = resp.data.content;
          if (newPage) {
            newPage.forEach(async (row) => {
              const info = await this.getProductInfo(row.productId);
              row.productInfo = info;

              // trigger vue reactive
              const index = this.results.findIndex((r) => r.id == row.id);
              this.$set(this.results, index, row);
            });
            await tagReservedInventories(newPage);
            this.results = this.results.concat(newPage);
          }
          this.totalPages = resp.data.totalPages;
          this.totalSizes = resp.data.totalElements;
          if (this.totalSizes > 0) {
            done();
          } else {
            empty();
          }
        })
        .catch(() => {
          this.clearSearch();
          this.addErrorMessage("Error looking up inventory, please try again.");
        });
    },
    async fetchUsingLocation({ page, done, empty }) {
      const params = {
        page,
        size: 5,
        sort: "id,desc",
        locationId: this.locationId,
        liteMode: true,
      };

      await this.$axios
        .get("/inventory/available-by-location", {
          params: { locationIds: this.locationId },
        })
        .then(async (resp) => {
          this.locationSummary = resp.data;
          if (this.locationSummary) {
            this.locationSummary.forEach(async (sum) => {
              const info = await this.getProductInfo(sum.productId);
              sum.productInfo = info;
            });
          }
        });

      this.$axios
        .get("/inventory/by-location", { params })
        .then(async (resp) => {
          const newPage = resp.data.content;
          if (newPage) {
            newPage.forEach(async (row) => {
              const info = await this.getProductInfo(row.productId);
              row.productInfo = info;

              // trigger vue reactive
              const index = this.results.findIndex((r) => r.id == row.id);
              this.$set(this.results, index, row);
            });
            await tagReservedInventories(newPage);
            this.results = this.results.concat(newPage);
          }
          this.totalPages = resp.data.totalPages;
          this.totalSizes = resp.data.totalElements;
          if (this.totalSizes > 0) {
            done();
          } else {
            empty();
          }
        })
        .catch((error) => {
          this.clearSearch();
          console.log(error);
          this.addErrorMessage("Error looking up inventory, please try again.");
        });
    },
    fetchUsingIds({ done }) {
      const ids = this.searchTerm
        .split(",")
        .map((n) => (n ? n.trim() : ""))
        .filter((n) => /^\d+$/.test(n))
        .join(",");
      const params = {
        ids,
        liteMode: true,
      };
      this.$axios
        .get("/inventory/by-ids", { params })
        .then(async (resp) => {
          const newPage = resp.data;
          if (newPage) {
            newPage.forEach(async (row) => {
              const info = await this.getProductInfo(row.productId);
              row.productInfo = info;

              // trigger vue reactive
              const index = this.results.findIndex((r) => r.id == row.id);
              this.$set(this.results, index, row);
            });

            await tagReservedInventories(newPage);
            this.results = this.results.concat(newPage);
          }
          this.totalPages = 1;
          this.totalSizes = newPage.length;
          done();
        })
        .catch(() => {
          this.clearSearch();
          this.addErrorMessage("Error looking up inventory, please try again.");
        });
    },
    search() {
      // this.$router.currentRoute.query = {method: this.searchMethod, search: this.searchTerm};
      // this.$router.push({name: 'inventorySearch', query: {method: this.searchMethod, search: this.searchTerm}})
      this.results = [];
      this.infiniteScrollKey = Date.now();
    },
    clearSearch() {
      if (this.$router.currentRoute.query.search) {
        this.$router.push({ name: "inventorySearch", query: {} });
      }

      this.totalSizes = null;
      this.searchTerm = null;
      this.results = [];
    },
    async getProductInfo(productId) {
      if (productId) {
        return await this.fetchCategory(productId).then((resp) => {
          const productInfo = resp;
          return productInfo;
        });
      } else {
        return null;
      }
    },
    unitMeasure(productInfo) {
      if (productInfo) {
        return productInfo.measureType == "Unit"
          ? productInfo.measureUnit
          : productInfo.measureWeight;
      } else {
        return "";
      }
    },
    viewInventoryDetails(inventory) {
      this.selectedInventoryId = inventory.id;
      this.showInventoryDetails = true;
    },
    locationName(locationId) {
      if (locationId && this.locations) {
        const location = this.locations.find((l) => l.id == locationId);
        if (location) {
          return location.name;
        }
      }
      return "n/a";
    },
    updateLocation(updatedInventory) {
      const inventory = this.results.find((i) => i.id == updatedInventory.id);
      inventory.locationId = updatedInventory.locationId
        ? updatedInventory.locationId
        : null;
      inventory.locationName = updatedInventory.locationName
        ? updatedInventory.locationName
        : null;
    },
    translateStatus(status){
      if(status == 'Available'){
        return this.$vuetify.lang.t('$vuetify.inventory.available')
      }
      else if(status == 'Not Available'){
        return this.$vuetify.lang.t('$vuetify.inventory.notAvailable')
      }
    }
  },
};
</script>

<style></style>
